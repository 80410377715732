<template>
  <div>
    <app-breadcrumb />
    <b-row>
      <b-col
        v-for="reserv in listOrder"
        :key="reserv.id"
        cols="12"
        md="6"
      >
        <b-card
          no-body
          border-variant="primary"
          class="p-1 mb-2"
        >
          <ul class="email-media-list ml-2">
            <b-media
              tag="li"
              no-body
            >
              <b-media-body>
                <div class="mail-items">
                  <h5 class="mt-1 mb-25">
                    {{ reserv.namacustomer }}
                  </h5>
                  <span class="text-truncate">{{ reserv.nohp }}</span>
                </div>
                <div class="mail-meta-item">
                  <feather-icon
                    icon="CalendarIcon"
                    size="14"
                    class="mr-1"
                  />
                  <span class="mail-date">{{ getDate(reserv.booking_untuk) }}</span>
                </div>
                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    {{ vertikalLayanan(reserv.vertikal_layanan) }}
                  </p>
                </div>
                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    {{ reserv.harga }}
                  </p>
                </div>
              </b-media-body>
            </b-media>

            <div class="d-flex text-center mt-2">
              <b-button
                variant="outline-danger"
                class="remove-wishlist mr-2"
                @click="deleteReservasi(reserv.id)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Hapus Reservasi</span>
              </b-button>
              <router-link
                :to="{ name: 'DetailReservasi', params: {id: reserv.id} }"
              >
                <b-button
                  variant="primary"
                  class="btn-cart move-cart"
                >
                  <feather-icon
                    icon="HeartIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">Lihat Detail</span>
                </b-button>
              </router-link>
            </div>
          </ul>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BMedia,
  BMediaBody,
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'
import { db } from '@/firebase'

export default {
  components: {
    BMedia,
    BMediaBody,
    AppBreadcrumb,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      listOrder: [],
    }
  },

  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      db.collection('reservasi').orderBy('waktu_pemesanan', 'desc').onSnapshot(snapshot => {
        const arr = []
        snapshot.docs.forEach(doc => {
          arr.push({
            id: doc.id,
            namacustomer: doc.data().nama_customer,
            harga: doc.data().harga,
            nohp: doc.data().no_hp,
            booking_untuk: doc.data().booking_untuk,
            status: doc.data().status,
            vertikal_layanan: doc.data().vertikal_layanan,
          })
          this.listOrder = [...new Set(arr)]
        })
      })
    },
    deleteReservasi(id) {
      this.$swal({
        title: 'Apakah anda yakin menghapus reservasi ini?',
        text: 'Konfirmasi jika anda ingin menghapus data reservasi',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      })
      db.collection('reservasi').doc(id).delete().then(() => {
        this.getOrder()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil menghapus data kabar',
            icon: 'TrashIcon',
            variant: 'success',
          },
        })
      })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.value,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
        })
    },
    getDate(unix) {
      const timestamp = unix
      const dateObject = new Date(timestamp)

      return dateObject.toLocaleString('id-ID', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', minute: 'numeric',
      })
    },
    statusReservasi(stasus) {
      if (status === 0) return { variant: 'light-primary', display: 'Membuat pesanan reservasi' }
      if (status === 1) return { variant: 'light-primary', display: 'Mendapatkan mitra' }
      if (status === 2) return { variant: 'light-primary', display: 'Customer melakukan pembayaran' }
      return { variant: 'light-secondary', display: 'Not found' }
    },
    vertikalLayanan(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }
      return 'Layanan Belum Diisi'
    },
    hapusReservasi(id) {
      this.$swal({
        title: 'Hapus data reservasi ini ?',
        text: 'Konfirmasikan jika anda ingin menghapus data',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('reservasi').doc(id).delete().then(() => {
            this.getOrder()
            this.$toast({
              conponent: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data reservasi',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },

  },
}
</script>

<style>

</style>
